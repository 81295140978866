<template>
  <div>
    <div v-if="user">
      <ValidationObserver
        v-if="user.business.is_template"
        v-slot="{ handleSubmit, invalid, touched }"
        ref="updateForm"
      >
        <form
          class="authentication-form"
          @submit.prevent="handleSubmit(updateBusiness)"
        >
          <b-tabs>
            <b-tab title="Template Description">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-4">
                      <UploadImage v-model="user.business.template_image" :business-id="user.business.id" label="Template image"/>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Description</label>
                    <editor v-model="user.business.template_description" :api-key="tinyMCEKey" :init="{ height: 250 }" :toolbar="customToolbar" :plugins="plugins"/>
                  </div>

                  <b-form-group>
                    <b-button
                      variant="primary"
                      :disabled="loadingUpdate || (invalid && touched)"
                      type="submit"
                    >
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else>Update</span>
                    </b-button>
                  </b-form-group>
                </div>
              </div>
            </b-tab>
            <b-tab title="Resources">
              <div class="card">
                <div class="card-body">
                  <div class="form-group">
                    <label>Content</label>
                    <editor v-model="user.business.template_content" :api-key="tinyMCEKey" :init="{ height: 600 }" :toolbar="customToolbar" :plugins="plugins"/>
                  </div>
                  <b-form-group>
                    <b-button
                      variant="primary"
                      :disabled="loadingUpdate || (invalid && touched)"
                      type="submit"
                    >
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else>Update</span>
                    </b-button>
                  </b-form-group>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </form>
      </ValidationObserver>
      <div v-else class="card">
        <!-- eslint-disable vue/no-v-html -->
        <div class="card-body" v-html="user.business.template_content"></div>
        <!--eslint-enable-->
      </div>
    </div>
    <div v-else class="text-center">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    'editor': Editor
  },

  data() {
    return {
      loadingUpdate: false,
      tinyMCEKey: null,
      plugins: 'imagetools image autolink link media lists',
      customToolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | image media',
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
  },

  methods: {
    updateBusiness() {
      this.loadingUpdate = true

      this.$store
        .dispatch('business/editSettings', this.user.business)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },
  },
}
</script>
